<template>
  <div class="bottom_box">
    <div class="bottom1 portion_box">
      <div class="bottom1_content Width_content">
        <div class="bottom1_logo">
          <img src="../assets/img/aclogo.png" alt="" />
        </div>
        <div class="bottom1_xian"></div>
        <div class="bottom1_logo">
          <img src="../assets/img/acphone.png" alt="" />
        </div>
        <div class="bottom1_text">
          <div class="bottom1_text_item">
            <img src="../assets/img/icon_location.png" alt="" />
            <span>杭州市拱墅区莫干山路972号泰嘉园K座1503室</span>
          </div>
          <div class="bottom1_text_item">
            <img src="../assets/img/icon_fax.png" alt="" />
            <span>传真--0571 - 87356950</span>
          </div>
          <div class="bottom1_text_item">
            <img src="../assets/img/icon_Eith.png" alt="" />
            <span
              >sales@achance.com.cn&nbsp;&nbsp;&nbsp;&nbsp;linx@achance.com.cn</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="bottom2 portion_box">
      <div>
        ©
        杭州安创科技有限公司&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;浙ICP备11051100号-1
        ©
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeBottom",
};
</script>

<style scoped lang="scss">
.portion_box {
  display: flex;
  justify-content: center;
}
.Width_content {
  width: 1200px;
}
.bottom_box {
  width: 100%;
  .bottom1 {
    background-color: #4764c3;
    padding: 60px 0;
    .bottom1_content {
      display: flex;
      align-items: center;
      padding: 0 30px;
      .bottom1_logo {
        height: 140px;
        img {
          height: 100%;
        }
      }
      .bottom1_xian {
        width: 2px;
        height: 110px;
        background-color: #fff;
        margin: 0 40px;
      }
      .bottom1_text {
        margin-left: 50px;
        // display: flex;
        .bottom1_text_item {
          display: flex;
          align-items: center;
          color: #ffffff;
          margin: 10px 0;
          img {
            width: 30px;
          }
          span {
            margin-left: 40px;
            font-size: 18px;
          }
        }
      }
    }
  }
  .bottom2 {
    background-color: #6180e6;
    padding: 26px 0;
    color: #fff;
    font-size: 18px;
  }
}
</style>