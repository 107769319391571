<template>
  <div class="home">
    <HomeTop :pitchname="'网站首页'"></HomeTop>
    <div class="acBg">
      <img src="../assets/img/acBg.png" alt="" />
    </div>
    <div class="portion2 portion_box">
      <div class="portion2_content Width_content">
        <div class="portion2_title">
          <div>杭州安创科技有限公司</div>
          <p>. &nbsp;. &nbsp;. &nbsp;. &nbsp;. &nbsp;.</p>
        </div>
        <div class="portion2_text">
          &nbsp;
          &nbsp;&nbsp;成立于2005年，致力于提供以云计算、大数据、互联网+等技术为核心的综合性IT解决方案和服务。公司扎根民政系统，以智慧殡葬、智慧陵园、智慧殡仪服务建设、运营服务及殡葬生态解决方案为抓手，联合相关领域优质资源布局智慧殡葬产业，构建大生态。
          <img src="../assets/img/companyProfile.png" alt="" />
        </div>
      </div>
    </div>
    <div class="portion3 portion_box">
      <div
        class="portion3_content Width_content"
        @click="$router.push('/CompanyProfile')"
      >
        <div class="portion3_left">
          <p>关于我们</p>
          <p>ABOUT&nbsp; US</p>
        </div>
        <div class="portion3_right">
          <span class="iconfont icon-xiangyou1"></span>
        </div>
      </div>
    </div>
    <div class="portion4 portion_box">
      <div class="portion4_content">
        <div>主要产品与服务</div>
        <p>. &nbsp;. &nbsp;. &nbsp;.</p>
      </div>
    </div>
    <div class="portion5 portion_box">
      <img class="portion5_bg" src="../assets/img/zycpyfwBg.png" alt="" />
      <div class="portion5_content Width_content">
        <div class="portion5_content_item" @click="btn(1)">
          <p class="portion5_content_ag">了解详情</p>
          <img src="../assets/img/portion5img1.png" alt="" />
        </div>
        <div class="portion5_content_item" @click="btn(0)">
          <p class="portion5_content_ag">了解详情</p>
          <img src="../assets/img/portion5img2.png" alt="" />
        </div>
        <div class="portion5_content_item" @click="btn(2)">
          <p class="portion5_content_ag">了解详情</p>
          <img src="../assets/img/portion5img3.png" alt="" />
        </div>
        <!-- <div></div>
        <div></div> -->
      </div>
    </div>
    <div class="portion6 portion_box">
      <div class="portion6_content Width_content">
        <div class="portion6_content_title">
          <div>全新智慧民政解决方案</div>
          <p>. &nbsp;. &nbsp;. &nbsp;.</p>
        </div>
        <div class="portion6_content_itembox">
          <div
            class="portion6_content_item ag"
            :class="portion6Index == index ? 'ag' : 'bg'"
            v-for="(item, index) in portion6List"
            :key="index"
          >
            <img
              v-if="portion6Index == index"
              class="item_logo"
              :src="item.url2"
              alt=""
            />
            <img v-else class="item_logo" :src="item.url1" alt="" />
            <p class="item_title">{{ item.text1 }}</p>
            <p class="item_text">{{ item.text2 }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="portion7 portion_box">
      <img src="../assets/img/portion7_bg.png" alt="" />
    </div>
    <div class="portion8 portion_box">
      <div class="Width_content">
        <div class="content_title">
          <div>系统演示</div>
          <p>. &nbsp;. &nbsp;. &nbsp;.</p>
        </div>
        <div class="portion8_carousel">
          <el-carousel :interval="4000" type="card">
            <el-carousel-item>
              <img
                class="carousel_item_img"
                src="../assets/img/carousel1.png"
                alt=""
              />
            </el-carousel-item>
            <el-carousel-item>
              <img
                class="carousel_item_img"
                src="../assets/img/carousel2.png"
                alt=""
              />
            </el-carousel-item>
            <el-carousel-item>
              <img
                class="carousel_item_img"
                src="../assets/img/carousel3.png"
                alt=""
              />
            </el-carousel-item>
          </el-carousel>
          <img class="carouselBg" src="../assets/img/carouselBg.png" alt="" />
        </div>
      </div>
    </div>
    <div class="portion9 portion_box">
      <div class="portion9_text">浙里逝安系统大屏</div>
    </div>
    <div class="portion10 portion_box">
      <div class="Width_content">
        <div class="content_title">
          <div>合作单位</div>
          <p>. &nbsp;. &nbsp;. &nbsp;.</p>
        </div>
        <div class="portion10_content">
          <div class="left iconfont icon-xiangzuo1" @click="prev"></div>
          <div class="carousel">
            <el-carousel ref="cardShow" :interval="4000">
              <el-carousel-item
                v-for="(item, index) in carouselList"
                :key="index"
              >
                <div class="carousel_item">
                  <div
                    class="item"
                    v-for="(item2, index2) in item.carouselList"
                    :key="index2"
                  >
                    <div
                      v-for="(item3, index3) in item2.carouselList"
                      :key="index3"
                    >
                      <p>{{ item3.value1 }}</p>
                      <p v-if="item3.value2">{{ item3.value2 }}</p>
                    </div>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="right iconfont icon-xiangyou1" @click="next"></div>
        </div>
      </div>
    </div>
    <HomeBottom></HomeBottom>
  </div>
</template>

<script>
// ©
import HomeTop from "@/components/HomeTop.vue";
// import HomeBottom from "@/components/HomeBottom.vue";
import HomeBottom from "@/components/HomeBottom.vue";
export default {
  name: "HomeView",
  components: {
    HomeTop,
    HomeBottom,
  },
  data() {
    return {
      carouselList: [
        {
          carouselList: [
            {
              carouselList: [
                {
                  value1: "浙江省农村信用社联合社",
                },
                {
                  value1: "浙江省民政事业发展中心",
                },
                {
                  value1: "浙江省审计厅",
                },
              ],
            },
            {
              carouselList: [
                {
                  value1: "浙江省农村信用社联合社",
                },
                {
                  value1: "浙江省民政事业发展中心",
                },
                {
                  value1: "浙江省审计厅",
                },
              ],
            },
          ],
        },
        {
          carouselList: [
            {
              carouselList: [
                {
                  value1: "浙江省农村信用社联合社",
                },
                {
                  value1: "浙江省民政事业发展中心",
                },
                {
                  value1: "浙江省审计厅",
                },
              ],
            },
            {
              carouselList: [
                {
                  value1: "浙江省农村信用社联合社",
                },
                {
                  value1: "浙江省民政事业发展中心",
                },
                {
                  value1: "浙江省审计厅",
                },
              ],
            },
          ],
        },
      ],
      portion6List: [
        {
          text1: "“一库”",
          text2: "民政专题区",
          url2: require("../assets/img/portion6_content_item_logo1.png"),
          url1: require("../assets/img/portion6_content_item_logo4.png"),
        },
        {
          text1: "“两云”",
          text2: "政务云+互联网云",
          url1: require("../assets/img/portion6_content_item_logo2.png"),
          url2: require("../assets/img/portion6_content_item_logo6.png"),
        },
        {
          text1: "“三板块”",
          text2: "智慧殡葬——智慧养老——智慧社工",
          url1: require("../assets/img/portion6_content_item_logo3.png"),
          url2: require("../assets/img/portion6_content_item_logo5.png"),
        },
      ],
      portion6Index: 0,
      timer: null,
    };
  },
  methods: {
    getStatus() {
      let thin = this;
      this.timer = setInterval(() => {
        if (thin.portion6Index == 2) {
          thin.portion6Index = 0;
        } else {
          thin.portion6Index++;
        }
      }, 3000);
    },
    prev() {
      this.$refs.cardShow.prev();
    },
    next() {
      this.$refs.cardShow.next();
    },
    btn(value) {
      this.$router.push(`/ServiceView?type=${value}`);
    },
  },
  mounted() {
    this.getStatus();
  },
  beforeDestroy() {
    this.timer = null;
  },
};
</script>
<style lang="scss" scoped>
.portion_box {
  display: flex;
  justify-content: center;
}
.Width_content {
  width: 1200px;
}
.content_title {
  // width: 100%;
  text-align: center;
  margin-top: 40px;
  div {
    font-size: 34px;
    font-weight: Bold;
  }
  p {
    font-size: 28px;
    margin: 0;
  }
}
.home {
  width: 100%;
  // min-height: 100%;
  .acBg > img {
    position: relative;
    z-index: -99;
    width: 100%;
    // min-height: 96vh;
    // background: url('../assets/img/acBg.png') no-repeat;
    // background-size: 100%;
  }
  .portion2 {
    padding: 30px 0;
    background: #fff;
    .portion2_title {
      width: 400px;
      text-align: center;
      div {
        margin-top: 20px;
        font-size: 40px;
        line-height: 60px;
        font-weight: Bold;
      }
      p {
        margin: 0 0 10px 0;
        font-size: 20px;
        font-weight: 700;
      }
    }
    .portion2_text {
      margin: 40px 0 100px 0;
      width: 600px;
      font-size: 30px;
      line-height: 50px;
      color: #333333;
      position: relative;
      img {
        position: absolute;
        height: 640px;
        top: -10px;
        right: -550px;
      }
    }
  }
  .portion3 {
    background-color: #4764c3;
    // height: 208px;
    padding: 50px 0;
    .portion3_content {
      display: flex;
      align-items: center;
      color: #ffffff;
      cursor: pointer;
      .portion3_left {
        margin-left: 50px;
        p {
          margin: 0;
          &:nth-child(1) {
            font-size: 32px;
            font-weight: Bold;
          }
          &:nth-child(2) {
            margin-top: 20px;
            font-size: 28px;
            font-weight: Medium;
          }
        }
      }
      .portion3_right {
        margin-left: 100px;
        width: 80px;
        height: 80px;
        background-color: rgba(255, 255, 255, 0.3);
        border-radius: 50%;
        line-height: 80px;
        text-align: center;
        span {
          font-size: 36px;
        }
      }
    }
  }
  .portion4 {
    background-color: #fff;
    padding: 80px 0;
    .portion4_content {
      color: #333333;
      text-align: center;
      div {
        font-size: 34px;
        font-weight: Bold;
      }
      p {
        font-size: 28px;
        margin: 0;
      }
    }
  }
  .portion5 {
    width: 100%;
    position: relative;
    .portion5_bg {
      width: 100%;
    }
    .portion5_content {
      height: 100%;
      position: absolute;
      margin: auto;
      // height: 200px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 1;
      > div {
        width: 32%;
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        &:hover {
          width: 33%;
          p {
            top: -260px;
            border: none;
            background-color: #ffc273;
          }
        }
        > p {
          position: absolute;
          border-radius: 0px 29.16px 0px 29.16px;
          right: 0;
          top: 0;
          z-index: 3;
          padding: 6px 14px;
          font-size: 16px;
          color: #fff;
          right: 14px;
          top: -252px;
          cursor: pointer;
        }
        .portion5_content_ag {
          border: 1px solid #fff;
        }
        // .portion5_content_bg {
        //   background-color: #ffc273;
        // }
        > img {
          position: absolute;
          width: 100%;
          z-index: 2;
        }
      }
    }
  }
  .portion6 {
    background-color: #fff;
    padding: 80px 0;
    .portion6_content_title {
      // width: 100%;
      text-align: center;
      margin-top: 40px;
      div {
        font-size: 34px;
        font-weight: Bold;
      }
      p {
        font-size: 28px;
        margin: 0;
      }
    }
    .portion6_content_itembox {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 80px;
      .portion6_content_item {
        width: 31%;
        height: 340px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;

        .item_logo {
          margin-left: 20px;
          height: 100px;
        }
        p {
          margin: 0;
          margin-left: 20px;
        }
        .item_title {
          font-size: 32px;
          font-weight: 700;
        }
        .item_text {
          font-size: 16px;
        }
      }

      .ag {
        color: #fff;
        background: url("../assets/img/portion6_content_item1.png") no-repeat;
        background-size: 100%;
      }
      .bg {
        color: #333;
        background: url("../assets/img/portion6_content_item2.png") no-repeat;
        background-size: 100%;
      }
    }
  }
  .portion7 {
    img {
      width: 100%;
    }
  }
  .portion8 {
    background-color: #fff;
    padding: 50px 0;
    .portion8_carousel {
      width: 100%;
      position: relative;
      margin-top: 80px;
      .carousel_item_img {
        // width: 100%;
        height: 100%;
      }
      .carouselBg {
        position: absolute;
        width: 672px;
        top: -22px;
        z-index: 1;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
      }
    }
  }
  .portion9 {
    background-color: #ebf2fd;
    padding: 30px 0;
    .portion9_text {
      font-size: 20px;
    }
  }
  .portion10 {
    background-color: #fff;
    padding: 40px 0;
    .portion10_content {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      color: #4764c3;
      .left,
      .right {
        font-size: 36px;
        cursor: pointer;
      }
      .carousel {
        width: 92%;
        height: 100%;
        .carousel_item {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .item {
            width: 100%;
            display: flex;
            justify-content: space-between;
            &:nth-child(1) {
              margin-bottom: 50px;
            }
            > div {
              width: 32%;
              border-radius: 20px;
              color: #333333;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              font-size: 18px;
              border: 1px solid #4764c3;
              padding: 10px 0;
              p {
                margin: 0;
                padding: 6px 0;
              }
            }
          }
        }
      }
    }
  }
}
.portion8 ::v-deep .el-carousel__indicators--outside {
  margin-top: 220px !important;
}
.portion8 ::v-deep .el-carousel__indicator--horizontal {
  padding: 8px 10px !important;
}
.portion8 ::v-deep .el-carousel__indicators--outside button {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #c0c0c0;
}
.portion8 ::v-deep .el-carousel__indicator.is-active button {
  width: 30px !important;
  height: 20px !important;
  border-radius: 10px !important;
  background-color: #4168ff !important;
}
.portion10 ::v-deep .el-carousel__arrow,
.portion8 ::v-deep .el-carousel__arrow {
  display: none;
}
.portion10 ::v-deep .el-carousel__indicators--outside {
  display: none;
}
.portion8 ::v-deep .el-carousel__container {
  height: 390px !important;
}
.portion10 ::v-deep .el-carousel__container {
  height: 300px !important;
}
</style>
