import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/ServiceView",
    name: "ServiceView",
    component: () => import("../views/ServiceView.vue"),
  },
  {
    path: "/CompanyProfile",
    name: "CompanyProfile",
    component: () => import("../views/CompanyProfile.vue"),
  },
  {
    path: "/CompanyNews",
    name: "CompanyNews",
    component: () => import("../views/CompanyNews.vue"),
  },
  {
    path: "/DynamicDetails",
    name: "DynamicDetails",
    component: () => import("../views/DynamicDetails.vue"),
  },
];

const router = new VueRouter({
  routes,
});
router.beforeEach((to, from, next) => {
  // 让页面回到顶部
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  window.pageYOffset = 0;
  next();
});

export default router;
