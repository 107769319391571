<template>
  <div class="topBOX">
    <div class="contentBox">
      <div class="logoBox">
        <img src="../assets/img/logo.png" alt="" />
      </div>
      <div class="right">
        <div
          @click="btn(item.url)"
          class="right_item"
          :class="item.name == pitchname ? 'pitch' : ''"
          v-for="(item, index) in list"
          :key="index"
        >
          {{ item.name }}
        </div>
        <div class="phone_box">
          <div class="iconfont icon-dianhua-xianxingyuankuang phone1"></div>
          <div class="phone2">0571-88850925</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeTop",
  props: ["pitchname"],
  data() {
    return {
      list: [
        {
          name: "网站首页",
          url: "/",
        },
        {
          name: "产品与服务",
          url: "/ServiceView",
        },
        { name: "新闻", url: "/CompanyNews" },
        { name: "关于安创", url: "/CompanyProfile" },
      ],
    };
  },
  methods: {
    btn(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style  lang="scss" scoped>
.topBOX {
  width: 100vw;
  height: 98px;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  .contentBox {
    width: 1200px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logoBox {
      width: 100px;
      // height: 37px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .right {
      height: 100%;
      display: flex;
      align-items: center;
      color: #fff;
      font-size: 16px;
      .right_item {
        padding: 0 22px;
        // width: 106px;
        // height: 35px;
        line-height: 35px;
        text-align: center;
        font-size: 16px;
        margin-left: 10px;
        cursor: pointer;
      }
      .pitch {
        background-color: #fdbc00;
        color: #333;
      }
      .phone_box {
        display: flex;
        align-items: center;
        margin-left: 10px;
        .phone1 {
          font-size: 22px;
          margin: 0 16px;
        }
      }
    }
  }
}
</style>